import React from "react";
import MessageCardInfoLine from "./MessageCardInfoLine";
import Button, { ButtonSize, ButtonVarint } from "../components/Button";
import { usePost } from "../lib/api";
import { useState } from "../lib/overmind";

const MessageCardDetails = ({ message, children }) => {
  const [post, isLoading, error, resp] = usePost();

  const state = useState().activeThread;

  if (!message) return null;
  const isFromShop = message.is_from_shop;

  let senderName = `${message.meta?.context?.user_first_name || ""} ${
    message.meta?.context?.last_name || ""
  }`.trim();
  if (!senderName) {
    senderName = message.meta?.context?.user_email;
  }

  const onClick = async () => {
    console.log("tmp: message", message);

    post(
      `/shop/{exid}/thread/${state.conversationId}/message/${message.sid}/resend`
    );
  };

  return (
    <>
      {children}
      <MessageCardInfoLine
        status={message.status}
        dateSent={message.date_sent}
        isFromShop={isFromShop}
        senderName={senderName}
      />
      <div className="d-flex">
        <div
          className="message-error text-danger ms-auto"
          hidden={!message.error_message}
        >
          {message.error_message}
          <Button
            size={ButtonSize.small}
            variant="btn-link"
            className="ms-1"
            showLoader={isLoading}
            onClick={onClick}
          >
            Resend Message
          </Button>
        </div>
      </div>
    </>
  );
};

export default MessageCardDetails;
