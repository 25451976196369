import React from "react";
import { Link } from "react-router-dom";
import OvermindDataTable from "../components/DataTable/OvermindDataTable";
import PaymentReport from "./PaymentReport";

const PaymentTable = () => {
  return (
    <div>
      <div className="my-3 d-flex align-items-center">
        <h5 className="my-0">Payments</h5>
        <Link to="create" className="btn btn-sm btn-primary ms-auto">
          New Payment Request
        </Link>
      </div>
      <div className="d-flex flex-column gap-3">
        <PaymentReport />
        <OvermindDataTable overmindPropertyKey="payments" />
      </div>
    </div>
  );
};

export default PaymentTable;
