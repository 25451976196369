import React from "react";
import { Icon } from "../IntegrationAppIcon";

const PaymentReportCard = ({ iconName, title, body }) => {
  return (
    <div className="card" style={{ minWidth: "175px" }}>
      <div className="card-body d-flex gap-1 flex-column">
        <Icon
          iconName={iconName}
          backgroundColor="#000000"
          width="30px"
          height="30px"
          fontSize="15px"
          fontColor="var(--bs-primary)"
          className="bg-primary-subtle border border-primary-subtle"
        />
        <span className="d-block mt-2 fs-15">{title}</span>
        <div className="d-flex gap-2">
          <div className="fw-bolde mb-0 fw-bold" style={{ fontSize: "22px" }}>
            {body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentReportCard;
