import React from "react";
import PaymentReportCard from "./PaymentReportCard";
import { useState } from "../lib/overmind";
import { formatCurrency } from "../lib/currency";

const PaymentReport = () => {
  const state = useState().payments;

  const conversionRate = Math.round(
    (state.metadata?.conversion_rate || 0) * 100
  );

  return (
    <div className="d-flex flex-row gap-3">
      <PaymentReportCard
        iconName="bi-chat-fill"
        title="Total Payments Sent"
        body={state.metadata?.total_sent}
      />
      <PaymentReportCard
        iconName="bi-currency-dollar"
        title="Text-to-Pay Revenue"
        body={formatCurrency((state.metadata?.total_paid || 0) / 100)}
      />
      <PaymentReportCard
        iconName="bi-bar-chart-fill"
        title="Payment Link Conversion Rate"
        body={`${conversionRate}%`}
      />
    </div>
  );
};

export default PaymentReport;
