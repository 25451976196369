/* eslint-disable camelcase */
import React from "react"
import { placeholder } from "lodash/fp"
import DepositStatusBadge from "../../../Payments/DepositStatusBadge"
import { reportError } from "../../errorHandler"
import { getTreatment } from "../../../Payments/PaymentStatusBadge"
import { PosSystem } from "../../pos"

const getTagVariant = (v) => {
  if (!v) return "secondary"
  const sanitizedVal = v.toLowerCase()
  console.log()
  switch (sanitizedVal) {
    case "workorder":
      return "primary"
    case "sale":
      return "success"
    default:
      return "secondary"
  }
}

export const get = async ({ state, actions, effects }) => {
  if (state.payments.isLoading) return

  state.payments.isLoading = true
  state.payments.responseError = null
  if (state.payments.tableData) {
    /*
    TODO: Maybe there needs to be one endpoint that loads the table configuration (e.g. filters) and another to fetch the data.
    Otherwise we have to maintain the last successful response so that the filter can still show.  This allows the user to
    either change the filter, reset the filter or rerun the report
    */
    state.payments.tableData.rows = null
  }

  try {
    const data = await effects.payments.getInvoices(state.payments.filterParams)
    state.payments.data = data.data
    state.payments.metadata = data.metadata
    actions.payments.makeDataTable(data.data)
  } catch (error) {
    reportError(error)
    state.payments.errorMessage = "There was an error"
    state.payments.responseError = error.response
  }
  state.payments.isLoading = false
}

export const makeDataTable = ({ state }, invoiceData) => {
  const rows = invoiceData.map((d) => ({
    id: d.external_id,
    url: `${d.external_id}`,
    data_bs_target: "#payment-detail-offcanvas",
    columns: [
      {
        value: d.date_created,
        type: "date",
      },
      {
        value: d.total / 100 || 0,
        currency: d.currency,
        type: "currency",
      },
      {
        value: d.status,
        type: "badge",
        ui_variant: getTreatment(d.status),
      },
      {
        value: d.deposit_metadata && d.deposit_metadata.pos_primary_type,
        type: "badge",
        ui_variant: getTagVariant(
          d.deposit_metadata && d.deposit_metadata.pos_primary_type
        ),
      },
      {
        value: d.line_item_description,
        style: { maxWidth: "55ch" },
      },
      {
        value: d.customer_name,
        className: "text-nowrap text-capitalize",
      },
      {
        value: () => (
          <DepositStatusBadge>{d.deposit_metadata?.status}</DepositStatusBadge>
        ),
        type: "function",
        hidden: state.account?.posSystem !== PosSystem.LightspeedRetail,
      },
    ],
  }))

  const headers = [
    "Created",
    "Total",
    "Status",
    "Type",
    "Description",
    "Customer",
  ]

  if (state.account?.posSystem === PosSystem.LightspeedRetail) {
    headers.push("Account Credit Status")
  }

  const tableData = {
    headers,
    rows,
    filter: [
      {
        type: "dropdown",
        title: "Date",
        key: "date",
        options: [
          {
            title: "Today",
            value: "today",
          },
          {
            title: "Yesterday",
            value: "yesterday",
          },
          {
            title: "This week",
            value: "this_week",
          },
          {
            title: "Last week",
            value: "last_week",
          },
          {
            title: "This Month",
            value: "this_month",
          },
          {
            title: "Last Month",
            value: "last_month",
          },
        ],
      },
      {
        type: "dropdown",
        title: "Status",
        key: "status",
        options: [
          {
            title: "Open",
            value: "open",
          },
          {
            title: "Paid",
            value: "paid",
          },
          {
            title: "Void",
            value: "void",
          },
          {
            title: "Uncollectible",
            value: "uncollectible",
          },
        ],
      },
      {
        type: "dropdown",
        title: "Tag",
        key: "tag",
        options: [
          {
            title: "Work Order",
            value: "workorder",
          },
          {
            title: "Sale",
            value: "sale",
          },
          {
            title: "Customer",
            value: "customer",
          },
        ],
      },
      {
        placeholder: "Search",
        type: "input",
        key: "query",
      },
    ],
  }

  state.payments.tableData = tableData
}

export const getDetail = ({ state }, id) =>
  state.payments.data?.find(({ external_id }) => external_id === id)

export const updateFilter = ({ state, actions }, params) => {
  state.payments.filterParams = params
  actions.payments.get()
}

export const voidPayment = async ({ state, actions, effects }, id) => {
  await effects.payments.voidPayment(id)
  const detail = actions.payments.getDetail(id)
  detail.status = "void"
  state.payments.data = [...state.payments.data]
  actions.payments.makeDataTable(state.payments.data)
}
