import React from "react";
import { useFormikContext } from "formik";
import ColorPickerControl from "../components/Form/ColorPickerControl";
import WebchatButtonDisplay from "./WebchatButtonDisplay";

const WebchatColorPicker = () => {
  const { values } = useFormikContext();
  return (
    <div className="d-flex gap-3">
      <ColorPickerControl id="theme_start_color" name="theme_start_color" />
      <WebchatButtonDisplay color={values.button_color} />
    </div>
  );
};

export default WebchatColorPicker;
