import React, { useEffect } from "react";
import moment from "moment";
import { useGet } from "../../lib/api";
import Loader from "../../components/Loader";
import APIResponseError from "../../components/APIResponseError";
import NpsLocationDataTable from "./NpsLocationDataTable";
import InlineStartEndDateForm from "../../components/Form/InlineStartEndDateForm";
import { formatDate, toISOString } from "../../lib/date";
import { LocalStorageKey } from "../../lib/localStorageKey";

const npsLocationGroupKey = new LocalStorageKey("ikNpsLocationGroupKey");

const NpsLocationOverview = () => {
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "days")
  );
  const [endDate, setEndDate] = React.useState(moment());
  const [groupId, setGroupId] = React.useState(npsLocationGroupKey.get());
  const [get, isLoading, error, resp] = useGet();

  useEffect(() => {
    get("/shop/{exid}/nps/location-aggregate-report", {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      group_id: groupId,
    });
  }, []);

  useEffect(() => {
    setGroupId(resp?.data?.meta?.selected_group_id);
    npsLocationGroupKey.set(resp?.data?.meta?.selected_group_id);
  }, [resp]);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <APIResponseError response={error} />;
  }

  return (
    <div>
      <div className="d-flex mb-2">
        <h5 className="my-3 me-auto">Location NPS Score</h5>
        <div className="fs-13 me-3" hidden={!resp?.data?.groups}>
          <label>Group</label>
          <select
            value={groupId}
            onChange={(e) => {
              setGroupId(e.target.value);
            }}
            className="form-select form-select-sm"
          >
            {resp?.data?.groups.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <InlineStartEndDateForm
          apiSubmitHandler={(data) => {
            setStartDate(data.start_date);
            setEndDate(data.end_date);
            get("/shop/{exid}/nps/location-aggregate-report", {
              start_date: toISOString(data.start_date),
              end_date: toISOString(data.end_date),
              group_id: groupId,
            });
          }}
          initialValues={{
            start_date: formatDate(startDate, "YYYY-MM-DD"),
            end_date: formatDate(endDate, "YYYY-MM-DD"),
          }}
        />
      </div>
      <NpsLocationDataTable data={resp?.data?.data} />
    </div>
  );
};

export default NpsLocationOverview;
