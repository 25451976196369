import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Checkbox from "../components/Form/Checkbox";

const WebchatLocationPicker = ({ locations }) => {
  return (
    <div>
      {locations.map(({ shop_id, name }) => {
        return <Checkbox id={`shop__${shop_id}`} label={name} />;
      })}
    </div>
  );
};

export default WebchatLocationPicker;
