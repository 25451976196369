import { Field, useFormikContext } from "formik";
import React from "react";

const Checkbox = ({ id, label, children, onChange, ...props }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <div className="form-check mb-2">
      <Field
        id={id}
        name={id}
        className="form-check-input mb-3"
        onChange={(e) => {
          onChange && onChange(e);
          setFieldValue(id, e.target.checked);
        }}
        type="checkbox"
        {...props}
      />
      <label className="form-check-label" htmlFor={id}>
        {label || children}
      </label>
    </div>
  );
};

export default Checkbox;
