import React from "react";
import FeaturePermissionCheck from "../components/FeaturePermissionCheck";
import WebchatUpgrade from "./WebchatUpgrade";
import WebchatForm from "./WebchatForm";
import WebchatCodeCreator from "./WebchatCodeCreator";
import SectionHeader from "../components/SectionHeader";
import WebchatInstallGuide from "./WebchatInstallGuide";

const Webchat = () => {
  const [formData, setFormData] = React.useState();
  const [hideWarning, setHideWarning] = React.useState(true);
  const [changeCount, setChangeCount] = React.useState(0);

  const onChange = (values) => {
    console.log("tmp: onChange", values);

    const locations = values?.locations.filter((location) => {
      return values[`shop__${location.shop_id}`];
    });

    setFormData({
      token: values?.token,
      color: values?.button_color,
      placement: values?.button_placement,
      offset: values?.bottom_offset,
      isOpen: values?.open_on_load,
      locations,
    });
    if (changeCount === 0) {
      setChangeCount(1);
      return;
    }
    setHideWarning(false);
  };

  return (
    <FeaturePermissionCheck
      permissionKey="website_widget"
      upgradeComponent={<WebchatUpgrade />}
    >
      <div className="d-flex gap-5">
        <div style={{ minWidth: "700px" }}>
          <SectionHeader className="mb-3">Webchat Widget</SectionHeader>
          <WebchatForm onChange={onChange} />
        </div>
        <div className="w-100">
          <div className="w-100 position-sticky top-0">
            <SectionHeader className="mb-3">Webchat Code</SectionHeader>
            <WebchatInstallGuide hideWarning={hideWarning} />
            <WebchatCodeCreator
              token={formData?.token}
              color={formData?.color}
              placement={formData?.placement}
              isOpen={formData?.isOpen}
              bottomOffset={formData?.offset}
              locations={formData?.locations}
            />
          </div>
        </div>
      </div>
    </FeaturePermissionCheck>
  );
};

export default Webchat;
